<script setup lang="ts">
  interface Props {
    show: boolean;
    color?: string;
    timeout?: number;
  }
  const props = withDefaults(defineProps<Props>(), {
    color: "main-02",
    timeout: 5000,
  });

  interface Emits {
    (event: "update:show", show: boolean): void;
  }
  const emit = defineEmits<Emits>();
  const computedData = computed<boolean>({
    get: () => props.show,
    set: value => {
      emit("update:show", value);
    },
  });
</script>

<template>
  <v-snackbar v-model="computedData" :color="color" location="top" :timeout="timeout" center>
    <div style="width: 100%; text-align: center" data-testid="alert-snack-bar">
      <slot></slot>
    </div>
  </v-snackbar>
</template>
